import Matter from "matter-js";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["canvas", "menu", "item"]

	connect() {
		if (this.render) return
		this.resizeNavigationItems()
		this.renderMatterjsCanvas()

		// listen to turbo visit to change canvas color if needed
		window.addEventListener("turbo:render", this.updateCanvasColors.bind(this))
	}

	/**
	 * Sets a new width and height on all navigation items so that they fit within
	 * the navigation area perfectly
	 */
	resizeNavigationItems() {
		this.currentItemDiameter = this.calculateBestItemSize(this.element.offsetWidth, this.element.offsetHeight, this.itemTargets.length)
		this.itemTargets.forEach(target => {
			target.style.width = `${this.currentItemDiameter}px`
			target.style.height = `${this.currentItemDiameter}px`
		});
	}

	/**
	 * Calculates the diameter of the circles in the navigation menu
	 * so that they take as much space as possible without overflowing
	 *
	 * @param {number} canvasWidth
	 * @param {number} canvasHeight
	 * @param {number} navigationItemsCount
	 * @returns {number}
	 */
	calculateBestItemSize(canvasWidth, canvasHeight, navigationItemsCount) {
		const MIN_DIAMETER = 20
		const canvasArea = canvasWidth * canvasHeight
		const bestFit = { itemSize: MIN_DIAMETER, coverage: this.getCircleAreaFromDiameter(MIN_DIAMETER) * navigationItemsCount / canvasArea };

    // Iterate over possible diameter lengths 5 pixels at a time to find best fit
    for (let itemSize = MIN_DIAMETER; itemSize <= Math.min(canvasWidth, canvasHeight); itemSize = itemSize + 5) {
			const itemsTotalArea = this.getCircleAreaFromDiameter(itemSize) * navigationItemsCount
			const cols = Math.floor(canvasWidth / itemSize);
			const rows = Math.floor(canvasHeight / itemSize);

			if (cols * rows >= navigationItemsCount) {
				// Check if new coverage is better than the previous one
				if (itemsTotalArea <= canvasArea && itemsTotalArea/canvasArea > bestFit.coverage) {
					bestFit.itemSize = itemSize
					bestFit.coverage = itemsTotalArea/canvasArea
				}
			}
    }

		return bestFit.itemSize
	}

	/**
	 * Returns circle area
	 *
	 * @param {number} diameter
	 * @returns {number}
	 */
	getCircleAreaFromDiameter(diameter) {
		return Math.PI * diameter * diameter / 4
	}

	/**
	 * Transform navigation item targets in Matterjs Bodies
	 * @returns {[Matter.Body, Matter.Body]}
	 */
	getItemsAsBodies() {
		return this.itemTargets.map((item, index) => {
			const r = item.offsetWidth/2;
			const randomPush = Math.random() * r
			const firstItemPush = (index == 2 ? 75 * r : 0);
			return Matter.Bodies.circle(item.offsetLeft + randomPush, item.offsetTop - randomPush - firstItemPush - this.element.offsetHeight * 1.5, r, {
				render: {
					fillStyle: "black",
					strokeStyle: "black",
					lineWidth: 3
		 		},
				menuItem: item
			})
		}, []);
	}

	/**
	 * Renders the canvas, its walls, mouse iteractions and the circles - Also
	 * adds event listeners for click and window resize events
	 */
	renderMatterjsCanvas() {
		this.engine = Matter.Engine.create()
		this.items = this.getItemsAsBodies()
		this.runner = Matter.Runner.create()
		this.render = Matter.Render.create({
			element: this.canvasTarget,
			engine: this.engine,
			options: {
				hasBounds: true,
				checkForBounds: true,
				width: this.element.offsetWidth,
				height: this.element.offsetHeight,
				wireframes: false,
				background: "white"
			}
		});
		this.mouse = Matter.Mouse.create(this.render.canvas)
		this.mouseConstraint = Matter.MouseConstraint.create(this.engine, {
			mouse: this.mouse,
			constraint: {
				stiffness: 0.2,
				render: {
					visible: false
				}
			}
		});
		this.render.mouse = this.mouse;

		this.WALL_THICKNESS = 750
		this.wallOptions = {
			isStatic: true,
			render: {
				fillStyle: "transparent",
				strokeStyle: "transparent",
				lineWidth: 0
			 }
		}
		this.bottomWall = Matter.Bodies.rectangle(this.element.offsetWidth/2, this.element.offsetHeight + this.WALL_THICKNESS/2, 10000, this.WALL_THICKNESS, this.wallOptions )
		this.rightWall = Matter.Bodies.rectangle(this.element.offsetWidth + this.WALL_THICKNESS/2, this.element.offsetHeight / 2, this.WALL_THICKNESS, 10000, this.wallOptions )
		this.leftWall = Matter.Bodies.rectangle(-this.WALL_THICKNESS/2, this.element.offsetHeight / 2, this.WALL_THICKNESS, 10000, this.wallOptions )

		Matter.Composite.add(this.engine.world, [this.mouseConstraint, ...this.items, this.bottomWall, this.rightWall, this.leftWall]);
		Matter.Render.run(this.render);
		Matter.Runner.run(this.runner, this.engine);

		// Add events and handle canvas and bodies colors
		this.updateCanvasColors()
		this.boundHandleMousedown = this.handleMouseDown.bind(this)
		Matter.Events.on(this.mouseConstraint, "mousedown", this.boundHandleMousedown);
		window.addEventListener("resize", this.resizeCanvas.bind(this));
	}

	/**
	 * Changes the colors of the canvas and circles - takes into account nightmode,
	 * if the item is the newest to show yellow border and if it's the current
	 * page to show it with inverted colors
	 */
	updateCanvasColors() {
		const isDarkMode = document.body.classList.contains("--nightmode")
		this.render.options.background = isDarkMode ? "black" : "white";
		this.items.forEach(body => {
			if (body.menuItem.querySelector("a").href == window.location) {
				body.render.fillStyle = "transparent"
				body.render.strokeStyle = isDarkMode ? "white" : "black"
				body.render.lineWidth = 3
			}
			else if (body.menuItem.getAttribute("data-item-index") == 2) {
				body.render.fillStyle = isDarkMode ? "white" : "black"
				body.render.strokeStyle = "yellow"
				body.render.lineWidth = 5
			}
			else {
				body.render.fillStyle = isDarkMode ? "white" : "black"
				body.render.strokeStyle = isDarkMode ? "white" : "black"
				body.render.lineWidth = 3
			}
		})
	}

	/**
	 * Resize canvas, moves right and bottom walls, resize circles and create
	 * a new mouse constraint after a window resize
	 */
	resizeCanvas() {
		this.render.bounds.max.x = this.element.offsetWidth;
		this.render.bounds.max.y = this.element.offsetHeight;
		this.render.options.width = this.element.offsetWidth;
		this.render.options.height = this.element.offsetHeight;
		this.render.canvas.width = this.element.offsetWidth;
		this.render.canvas.height = this.element.offsetHeight;
		Matter.Render.setPixelRatio(this.render, window.devicePixelRatio);

		// Move walls to fit new size
		Matter.Body.setPosition(this.rightWall,
			Matter.Vector.create(
				this.element.offsetWidth + this.WALL_THICKNESS/2,
				this.element.offsetHeight/2
			)
		)
		Matter.Body.setPosition(this.bottomWall,
			Matter.Vector.create(
				this.element.offsetWidth/2,
				this.element.offsetHeight + this.WALL_THICKNESS/2
			)
		)

		// Remove events and previous mouse from composite and add a new one to fit new size
		Matter.Events.off(this.mouseConstraint, "mousedown", this.boundHandleMousedown);
		Matter.Composite.remove(this.engine.world, this.mouseConstraint)
		this.mouse = Matter.Mouse.create(this.render.canvas)
		this.mouseConstraint = Matter.MouseConstraint.create(this.engine, {
			mouse: this.mouse,
			constraint: {
				stiffness: 0.2,
				render: {
					visible: false
				}
			}
		});
		this.render.mouse = this.mouse;
		Matter.Composite.add(this.engine.world, this.mouseConstraint)
		Matter.Events.on(this.mouseConstraint, "mousedown", this.boundHandleMousedown);

		// Resize circles to fit size
		const newItemDiameter = this.calculateBestItemSize(this.element.offsetWidth, this.element.offsetHeight, this.items.length)
		this.items.forEach(body => {
			Matter.Body.scale(body, newItemDiameter/this.currentItemDiameter, newItemDiameter/this.currentItemDiameter)
		})
		this.currentItemDiameter = newItemDiameter
	}

	/**
	 * Visit a page using Turbo if clicking on a navigation item circle
	 *
	 * @param {Event} event - Mousedown event
	 */
	handleMouseDown (event) {
		const {x,y} = event.mouse.position;
		const bodiesUnder = Matter.Query.point(this.items, { x: x, y: y });
		if (!bodiesUnder.length || bodiesUnder[0].menuItem.querySelector("a").href == window.location.href) return
		Turbo.visit(bodiesUnder[0].menuItem.querySelector("a").href)
	}
}
