import '../css/main.css'
import { Application } from '@hotwired/stimulus'
import Player from './controllers/player_controller'
import Audio from './controllers/audio_controller'
import Draggable from './controllers/draggable_controller'
import Navigation from './controllers/navigation_controller'
import * as Turbo from "@hotwired/turbo"

const application = Application.start()
application.register('audio', Audio)
application.register('draggable', Draggable)
application.register('player', Player)
application.register('navigation', Navigation)
