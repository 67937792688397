import { Controller } from '@hotwired/stimulus'

// TODO: implement mobile compatible version
// https://stackoverflow.com/questions/70549522/how-to-make-a-draggable-for-touch
export default class extends Controller {
	connect() {
		this.posX = 0;
		this.posY = 0;
		this.mouseX = 0;
		this.mouseY = 0;
		this._boundMoveElement = this._moveElement.bind(this)

		this.element.addEventListener('mousedown', this._mouseDown.bind(this))
		document.addEventListener('mouseup', this._mouseUp.bind(this));
	}

	_mouseDown(e) {
		e.preventDefault();
		this.posX = e.clientX - this.element.offsetLeft;
		this.posY = e.clientY - this.element.offsetTop;
		document.addEventListener('mousemove', this._boundMoveElement);
	}

	_mouseUp() {
		document.removeEventListener('mousemove', this._boundMoveElement);
	}

	_moveElement(e) {
		this.mouseX = e.clientX - this.posX;
		this.mouseY = e.clientY - this.posY;
		this.element.style.left = this.mouseX + 'px';
		this.element.style.top = this.mouseY + 'px';
	}
}
